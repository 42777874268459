import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowDown, FaStar, FaGem, FaMedal } from "react-icons/fa6";
import { motion, useInView } from "framer-motion";

// Estilos para la animación flip
const styles = `
  .perspective-1000 {
    perspective: 1000px;
  }
  
  .preserve-3d {
    transform-style: preserve-3d;
    transition: transform 0.6s;
  }
  
  .backface-hidden {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }
  
  .rotate-y-180 {
    transform: rotateY(180deg);
  }

  .diamond-border {
    position: relative;
    border-radius: 20px;
    overflow: visible !important;
  }

  .diamond-border::before {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    background: linear-gradient(45deg, #7FE6FF40, transparent 60%);
    border-radius: 24px;
    animation: borderGlow 2s linear infinite;
    z-index: -1;
    filter: blur(3px);
    pointer-events: none;
  }

  .diamond-border::after {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    background: linear-gradient(-45deg, transparent 40%, #7FE6FF30);
    border-radius: 23px;
    animation: borderGlow 3s linear infinite reverse;
    z-index: -1;
    filter: blur(2px);
    pointer-events: none;
  }

  @keyframes borderGlow {
    0% { background-position: 0% 0%; }
    100% { background-position: 200% 0%; }
  }

  .diamond-icon {
    position: relative;
  }

  .diamond-icon::before {
    content: '✦';
    position: absolute;
    top: -3px;
    right: -1px;
    font-size: 15px;
    color: #7FE6FF;
    animation: twinkle 2.5s ease-in-out infinite;
    text-shadow: 0 0 5px rgba(127,240,255,0.8);
  }

  @keyframes twinkle {
    0%, 100% { opacity: 0; transform: scale(0.8); }
    50% { opacity: 1; transform: scale(1.2); }
  }
`;

// Inyectar los estilos en el head
if (typeof document !== 'undefined') {
  const styleSheet = document.createElement("style");
  styleSheet.innerText = styles;
  document.head.appendChild(styleSheet);
}

// Example images - replace with actual paths
const headerImages = [
  "https://i.postimg.cc/YSgv9kw8/pexels-luis-zambrano-3782493-18664656.jpg",
  "https://i.postimg.cc/ncnVXLHg/pexels-misha-earle-366060-1777843.jpg",
  "https://i.postimg.cc/RZ0ct92R/Whats-App-Image-2024-07-28-at-17-16-50-2.jpg",
];

const plataImage = "https://i.postimg.cc/ncnVXLHg/pexels-misha-earle-366060-1777843.jpg";
const oroImage = "https://i.postimg.cc/9QnVCwQM/pexels-angel-ayala-321556-27903364.jpg";
const diamanteImage = "https://i.postimg.cc/5yV4q48H/Sin_título_(4000_x_2500_px).png";

const packages = [
  {
    name: "DIAMOND",
    tagline: "The Ultimate Experience",
    icon: <FaGem className="text-4xl text-[#7FE6FF] drop-shadow-[0_0_8px_rgba(127,230,255,0.6)]" />,
    imageUrl: diamanteImage,
    designs: [
      {
        preciceDec: "130",
        precice: "59.95",
        name: "Example with Photos",
        link: "/wedding-auto-diamond?idInvitado=49674",
        name2: "Example without Photos",
        link2: "/boda-diamante-ejemplo-fotos?name=Angel%20Jaimes",
      },
    ],
  },
  {
    name: "GOLD",
    icon: <FaStar className="text-3xl text-[#FFD700]" />,
    imageUrl: oroImage,
    designs: [
      {
        preciceDec: "90",
        precice: "39.95",
        name: "Example with Photos",
        link: "/en/wedding-auto?id=3584",
        name2: "Example without Photos",
        link2: "/boda-oro-ejemplo-fotos",
      },
    ],
  },
  {
    name: "SILVER",
    icon: <FaMedal className="text-3xl text-[#B0B0B0]" />,
    imageUrl: plataImage,
    designs: [
      {
        preciceDec: "50",
        precice: "19.95",
        name: "Example with Photos",
        link: "/en/wedding-auto?id=3323",
        name2: "Example without Photos",
        link2: "/boda-plata-ejemplo-fotos",
      },
    ],
  },
];

export const EjemplosBodaEn = () => {
  const [typedText, setTypedText] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showOfferBanner, setShowOfferBanner] = useState(false);
  const [countdown, setCountdown] = useState(() => {
    const savedEndTime = localStorage.getItem('countdownEndTime');
    const now = new Date().getTime();
    
    // Si no hay tiempo guardado o si han pasado 3 días desde la última vez
    if (!savedEndTime || now >= parseInt(savedEndTime)) {
      // Establecer un nuevo tiempo final (3 días a partir de ahora)
      const threeDays = 3 * 24 * 60 * 60 * 1000; // 3 días en milisegundos
      const newEndTime = now + threeDays;
      localStorage.setItem('countdownEndTime', newEndTime.toString());
      
      // Valores iniciales para el contador
      return {
        hours: 71,
        minutes: 59,
        seconds: 59
      };
    }

    // Si hay un tiempo guardado y no han pasado 3 días, calcular el tiempo restante
    const timeLeft = Math.max(0, parseInt(savedEndTime) - now);
    const hours = Math.floor(timeLeft / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
    return { hours, minutes, seconds };
  });
  const typingIntervalRef = useRef(null);
  const sliderRef = useRef(null);
  const [flippedCards, setFlippedCards] = useState({});

  const slideTexts = [
    "Wedding Invitations",
    "Exclusive Promotions",
    "Customized Your Way",
    "Check Out Some Examples"
  ];

  useEffect(() => {
    // Start typing effect for first slide
    startTypingEffect();
    
    // Make sure slider starts from first slide
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
  }, []);

  const startTypingEffect = () => {
    let i = 0;
    if (typingIntervalRef.current) {
      clearInterval(typingIntervalRef.current);
    }
    
    const currentText = slideTexts[currentSlide];
    
    typingIntervalRef.current = setInterval(() => {
      if (i < currentText.length) {
        setTypedText(prev => currentText.substring(0, i + 1));
        i++;
      } else {
        clearInterval(typingIntervalRef.current);
      }
    }, 50);
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    initialSlide: 0,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
      setTypedText("");
      startTypingEffect();
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-16738412224";
    script.async = true;
    document.head.appendChild(script);

    const scriptText = document.createElement('script');
    scriptText.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-16738412224');
    `;
    document.head.appendChild(scriptText);

    // Start typing effect when component mounts
    startTypingEffect();

    // Clean up interval on unmount
    return () => {
      if (typingIntervalRef.current) {
        clearInterval(typingIntervalRef.current);
      }
    };
  }, []);

  // Efecto para el contador regresivo
  useEffect(() => {
    const timer = setInterval(() => {
      const savedEndTime = localStorage.getItem('countdownEndTime');
      const now = new Date().getTime();
      
      // Si no hay tiempo guardado o si han pasado 3 días
      if (!savedEndTime || now >= parseInt(savedEndTime)) {
        // Establecer un nuevo tiempo final (3 días a partir de ahora)
        const threeDays = 3 * 24 * 60 * 60 * 1000;
        const newEndTime = now + threeDays;
        localStorage.setItem('countdownEndTime', newEndTime.toString());
        
        // Reiniciar el contador
        setCountdown({
          hours: 71,
          minutes: 59,
          seconds: 59
        });
        return;
      }

      // Calcular tiempo restante
      const timeLeft = Math.max(0, parseInt(savedEndTime) - now);
      
      if (timeLeft === 0) {
        clearInterval(timer);
        setCountdown({ hours: 0, minutes: 0, seconds: 0 });
        return;
      }

      const hours = Math.floor(timeLeft / (1000 * 60 * 60));
      const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
      
      setCountdown({ hours, minutes, seconds });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleFlip = (pkgName) => {
    setFlippedCards(prev => ({
      ...prev,
      [pkgName]: !prev[pkgName]
    }));
  };

  return (
    <>
      <div className="min-h-screen font-['Roboto']" style={{
        backgroundImage: `url('https://imagizer.imageshack.com/img924/3525/h9Gkfe.png')`,
        backgroundRepeat: 'repeat',
        backgroundSize: '300px',
        backgroundColor: 'white'
      }}>
        <div className="relative mx-auto overflow-x-hidden">
          <div className="w-full max-w-[1400px] mx-auto">
            <Slider ref={sliderRef} {...sliderSettings}>
              {headerImages.map((image, index) => (
                <div key={index} className="relative h-[60vh] md:h-[70vh]">
                  <img
                    src={image}
                    className="h-full w-full object-cover"
                    alt=""
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <motion.p 
                      className="text-2xl md:text-4xl font-['Playfair Display'] text-white text-center px-4 py-2 rounded-lg"
                    >
                      {typedText}
                    </motion.p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>

          {/* Countdown timer with new design */}
          <div className="flex items-center justify-center my-4 px-4">
            <div className="bg-white rounded-lg shadow-md px-6 py-3 flex items-center gap-1">
              <span className="flex items-center gap-2">
                <span className="text-yellow-400 text-xl">✨</span>
                <span className="font-semibold">Special offer ends in:</span>
              </span>
              <span className="font-mono font-bold text-red-600">
                {String(countdown.hours).padStart(2, '0')}:
                {String(countdown.minutes).padStart(2, '0')}:
                {String(countdown.seconds).padStart(2, '0')}
              </span>
            </div>
          </div>

          <motion.div 
            className="max-w-[1400px] mx-auto px-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="flex flex-wrap justify-center gap-6">
              {packages.map((pkg, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ 
                    duration: 0.7,
                    delay: index * 0.2,
                    ease: "easeOut"
                  }}
                  className="w-[90%] md:w-[46%] lg:w-[31%]"
                >
                  <div className="flex flex-col items-center mt-1">
                    <div className="flex items-center justify-center">
                      {pkg.name === "DIAMOND" && (
                        <div className="flex items-center justify-center">
                          <div className="diamond-icon">
                            {pkg.icon}
                          </div>
                        </div>
                      )}
                      {pkg.name === "GOLD" && (
                        <div className="flex items-center justify-center">
                          {pkg.icon}
                        </div>
                      )}
                      {pkg.name === "SILVER" && (
                        <div className="flex items-center justify-center">
                          {pkg.icon}
                        </div>
                      )}
                    </div>
                    <h2 className="text-md font-['Playfair Display'] font-bold text-black text-center tracking-wider">
                      {pkg.name}
                    </h2>
                  </div>

                  <div className="perspective-1000">
                    <div 
                      className={`relative bg-white rounded-[20px] preserve-3d ${flippedCards[pkg.name] ? 'rotate-y-180' : ''} transition-transform duration-500 h-[480px] ${
                        pkg.name === "DIAMOND" ? 
                          "border-[3px] border-[#7FE6FF] diamond-border" :
                        pkg.name === "GOLD" ? 
                          "border-[2px] border-[#FFD700]" :
                          "border-[2px] border-[#B0B0B0]"
                      }`}
                    >
                      
                      {/* Front of card */}
                      <div className="backface-hidden w-full absolute inset-0">
                        <div className="relative w-full bg-white" style={{ borderRadius: '17px' }}>
                          {pkg.name === "DIAMOND" && (
                            <div className="absolute left-0 bg-gradient-to-r from-[#7FE6FF] to-[#5BC9FF] text-white text-xs font-bold px-3 py-1 rounded-full shadow-lg z-[2] animate-pulse">
                              MOST POPULAR
                            </div>
                          )}
                          {pkg.name === "GOLD" && (
                            <div className="absolute  left-0 bg-gradient-to-r from-[#FFD700] to-[#FFA500] text-white text-xs font-bold px-3 py-1 rounded-full shadow-lg z-[2] animate-pulse">
                              NEW
                            </div>
                          )}
                          <div className="w-full">
                            <img
                              src={pkg.imageUrl}
                              alt={`${pkg.name} package`}
                              className="w-full h-72 object-cover rounded-t-[17px]"
                            />
                          </div>
                        </div>
                        
                        {pkg.designs.map((design, idx) => (
                          <div key={idx} className="p-3">
                            <div className="text-center">
                              <div className="flex items-center justify-center gap-2 mb-2">
                                <span className="text-gray-500 text-sm">
                                  <span className="line-through">${design.preciceDec}</span>
                                </span>
                                <span className="text-black text-lg font-bold">
                                  ${design.precice}
                                </span>
                              </div>
                              <button 
                                onClick={() => handleFlip(pkg.name)}
                                className="bg-green-200 hover:bg-green-400 text-gray-800 px-3 py-2 rounded-md text-xs transition-colors duration-200 mb-3"
                              >
                                What's included?
                              </button>

                              <div className="space-y-2">
                                <a
                                  href={design.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="flex items-center justify-center gap-2 py-1.5 px-2 bg-gray-300 text-black rounded-full hover:bg-gray-400 transition-all duration-300 shadow-md hover:shadow-lg no-underline text-[10px] tracking-wide whitespace-nowrap overflow-hidden"
                                >
                                  <svg height="16" width="16" fill="#4499ff" viewBox="0 0 24 24" data-name="Layer 1" id="Layer_1" className="sparkle">
                                    <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
                                  </svg>
                                  <span>{design.name}</span>
                                </a>
                                
                                <a
                                  href={design.link2}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="flex items-center justify-center gap-2 py-1.5 px-2 bg-gray-300 text-black rounded-full hover:bg-gray-400 transition-all duration-300 shadow-md hover:shadow-lg no-underline text-[10px] tracking-wide whitespace-nowrap overflow-hidden"
                                >
                                  <svg height="16" width="16" fill="#4499ff" viewBox="0 0 24 24" data-name="Layer 1" id="Layer_1" className="sparkle">
                                    <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
                                  </svg>
                                  <span>{design.name2}</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* Back of card */}
                      <div className="absolute inset-0 backface-hidden rotate-y-180 bg-white rounded-[20px] overflow-y-auto">
                        <div className="p-4">
                          {pkg.name === "SILVER" && (
                            <div className="space-y-2">
                              <h3 className="text-lg font-bold text-gray-900 mb-4">Includes:</h3>
                              <ul className="text-sm space-y-2 text-gray-900 p-0 list-none">
                                <li className="flex items-start">
                                  <FaMedal className="text-[#B0B0B0] text-xs mt-1 flex-shrink-0 mr-1" />
                                  <span>Share your invitation to any mobile device</span>
                                </li>
                                <li className="flex items-start">
                                  <FaMedal className="text-[#B0B0B0] text-xs mt-1 flex-shrink-0 mr-1" />
                                  <span>Event description</span>
                                </li>
                                <li className="flex items-start">
                                  <FaMedal className="text-[#B0B0B0] text-xs mt-1 flex-shrink-0 mr-1" />
                                  <span>Countdown timer</span>
                                </li>
                                <li className="flex items-start">
                                  <FaMedal className="text-[#B0B0B0] text-xs mt-1 flex-shrink-0 mr-1" />
                                  <span>Colors and background themes</span>
                                </li>
                                <li className="flex items-start">
                                  <FaMedal className="text-[#B0B0B0] text-xs mt-1 flex-shrink-0 mr-1" />
                                  <span>Background music</span>
                                </li>
                                <li className="flex items-start">
                                  <FaMedal className="text-[#B0B0B0] text-xs mt-1 flex-shrink-0 mr-1" />
                                  <span>Couple's appreciation</span>
                                </li>
                                <li className="flex items-start">
                                  <FaMedal className="text-[#B0B0B0] text-xs mt-1 flex-shrink-0 mr-1" />
                                  <span>Ceremony and reception address with Google Maps</span>
                                </li>
                                <li className="flex items-start">
                                  <FaMedal className="text-[#B0B0B0] text-xs mt-1 flex-shrink-0 mr-1" />
                                  <span>Include 3 of your favorite photos</span>
                                </li>
                              </ul>
                            </div>
                          )}
                          {pkg.name === "GOLD" && (
                            <div className="space-y-2">
                              <h3 className="text-lg font-bold text-gray-900 mb-4">Includes:</h3>
                              <ul className="text-sm space-y-2 text-gray-800 p-0 list-none">
                                <li className="flex items-start">
                                  <FaStar className="text-[#FFD700] text-xs mt-1 flex-shrink-0 mr-1" />
                                  <span>Everything in Silver</span>
                                </li>
                                <li className="flex items-start">
                                  <FaStar className="text-[#FFD700] text-xs mt-1 flex-shrink-0 mr-1" />
                                  <span>Activities schedule</span>
                                </li>
                                <li className="flex items-start">
                                  <FaStar className="text-[#FFD700] text-xs mt-1 flex-shrink-0 mr-1" />
                                  <span>Custom dress code</span>
                                </li>
                                <li className="flex items-start">
                                  <FaStar className="text-[#FFD700] text-xs mt-1 flex-shrink-0 mr-1" />
                                  <span>Special text fonts</span>
                                </li>
                                <li className="flex items-start">
                                  <FaStar className="text-[#FFD700] text-xs mt-1 flex-shrink-0 mr-1" />
                                  <span>Gift registry or money gift option</span>
                                </li>
                                <li className="flex items-start">
                                  <FaStar className="text-[#FFD700] text-xs mt-1 flex-shrink-0 mr-1" />
                                  <span>Gallery of 4 additional photos</span>
                                </li>
                              </ul>
                            </div>
                          )}
                          {pkg.name === "DIAMOND" && (
                            <div className="space-y-2">
                              <h3 className="text-lg font-bold text-gray-900 mb-4">Includes:</h3>
                              <ul className="text-sm space-y-2 text-gray-800 p-0 list-none">
                                <li className="flex items-start">
                                  <FaGem className="text-[#7FE6FF] text-xs mt-1 flex-shrink-0 mr-1" />
                                  <span>Everything in Gold</span>
                                </li>
                                <li className="flex items-start">
                                  <FaGem className="text-[#7FE6FF] text-xs mt-1 flex-shrink-0 mr-1" />
                                  <span>Unique invitations for each guest, with their name, a special message and assigned passes</span>
                                </li>
                                <li className="flex items-start">
                                  <FaGem className="text-[#7FE6FF] text-xs mt-1 flex-shrink-0 mr-1" />
                                  <span>Backgrounds specially designed for you</span>
                                </li>
                                 <li className="flex items-start">
                                  <FaGem className="text-[#7FE6FF] text-xs mt-1 flex-shrink-0 mr-1" />
                                  <span>Accommodation suggestions</span>
                                </li>
                                <li className="flex items-start">
                                  <FaGem className="text-[#7FE6FF] text-xs mt-1 flex-shrink-0 mr-1" />
                                  <span>Priority attention</span>
                                </li>
                                <li className="flex items-start">
                                  <FaGem className="text-[#7FE6FF] text-xs mt-1 flex-shrink-0 mr-1" />
                                  <span>Complete gallery of up to 8 of your photos</span>
                                </li>
                              </ul>
                            </div>
                          )}
                          <div className="flex justify-center">
                            <button 
                              onClick={() => handleFlip(pkg.name)}
                              className="mt-4 bg-green-200 hover:bg-green-300 text-gray-800 px-4 py-2 rounded-md text-sm transition-colors duration-200"
                            >
                              Back
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>

          <div className="h-16"></div>
        </div>
      </div>
    </>
  );
};
